module.exports = `
<!DOCTYPE html>
<html prefix="og: http://ogp.me/ns#" lang="en">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="../css/main.css">
</head>

<body class="bg-white">
  <style>
    .privacy-link {
      word-break: break-word;
      line-height: 12px !important;
    }

    p,
    li {
      font-size: 14px;
      line-height: 20px !important;
    }

    td,
    th {
      border: 1px solid;
      font-size: 14px;
      padding: 5px;
    }
  </style>
  <div class="container-fluid px-0">
    <div class="container">
      <div class="row">
        <div class="container blog px-2">
          <h1 style="font-size:15px;margin-top:40px;">VIDEO DOWNLOADER PRIVACY POLICY</h1>
          <div style="margin-top:25px;">
            <div style="font-size:21px;font-weight:500;line-height:25px;margin-bottom:20px;">
              When you use our services, you’re trusting us with your information. We understand this
              is a big responsibility and work hard to protect your information and put you in control.</div>
            <p style="font-size:14px;line-height:20px;">This Privacy Policy is meant to help you understand what
              information we collect, why we collect it, and how you can manage and delete your information. IF YOU DO
              NOT AGREE TO THIS POLICY, PLEASE DO NOT USE OUR SERVICES.<br><br>Effective November 9, 2019</p>
            <hr>
            <p style="font-size:14px; font-weight:bold;margin-top:30px;">INFORMATION VIDEO DOWNLOADER COLLECTS</p>
            <p>We want you to understand the types of information we collect as you use our services.</p>
            <p>We collect information to provide better services to all our users — from figuring out basic stuff like
              which language you speak, to more complex things like which ads you’ll find most useful.</p>
            <p>When you use our serives, we store the information we collect
              with unique identifiers tied to the application, or device you’re using. This helps us do things like
              maintain your language preferences across sessions.</p>
            <p>All the personal sensitive information like email address are stored in their encrypted/hashed forms on
              our servers.</p>
            <p style="font-weight:bold">The following table explains the information collected by Video Downloader on its
              servers :</p>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th>Information</th>                  
                  <th></th>
                </tr>
                <tr>
                  <td>Operating System Version</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Application Version</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Coarse Location *</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Device Language</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Screen Width</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Screen Height</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Screen Pixel Density</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Device CPU Architecture</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Device Model, Manufacturer</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Timezone</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>IP Address</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
                <tr>
                  <td>Google Advertiser ID (GAID)</td>
                  <td><span class="fa-circle fa" style="color:#35B757"></span></td>
                </tr>
              </tbody>
            </table>
            <p style="margin-top:30px;">* only coarse (approximate) location is collected by Video Downloader. We do not collect
              fine (exact) location of our users.</p>
            <p>** We also use third party analytics tools like Firebase and collect data about what users search, watch,
              download etc. This data is not used for any targetting or recommendations, neither is it shared with any
              other sdks. It is also not linked to any personal info like email address or IP address. The purpose of
              such analysis is knowing what is trending in various regions and for improving user experience.</p>
            <h5 class="header-text-dark">Why do we collect the above information about our users ?</h5>
            <p>All the information that is collected by us is used for providing better user experience to our users.
              For example storing screen size information helps us show only those videos in suggestions that are best
              suited for the device. Location information helps us target local region videos in user’s feed.</p>
            <h5 class="header-text-dark">Do we share your information with third party SDKs provider ?</h5>
            <p>Like any other popular application, Video Downloader uses a lot of third party SDKs to provide many
              functionalities. For example Video Downloader uses many ad network SDKs to provide in app ads for
              revenue.<b>&nbsp; We do not explicitly provide any private information to any of these third party
                SDK.</b></p>
            <p>Since these SDKs are bundled with the application and have access to all the permissions granted to
              Video Downloader, many of them collect information for identifying users and then targeting them with relevant
              content. For example Ad networks SDKs generally collect coarse location to better target users with
              relevant local ads. We have made sure that all the SDKs we use in our app are legally
              compliant with Privacy laws across the globe, including the new GDPR implemented in EU region. The sdks
              included in the application and links to their privacy policies are provided below :</p>
            <div class="mb-1">
              <div style="font-weight:bold;font-size:14px;">Firebase analytics by Google</div>
              <div style="font-size:12px;"><a class="privacy-link" href="https://firebase.google.com/support/privacy/"
                  target="_blank">https://firebase.google.com/support/privacy/</a></div>
            </div>
            <div class="mb-1">
              <div style="font-weight:bold;font-size:14px;">Fabric Analytics and Crashlytics</div>
              <div style="font-size:12px;"><a class="privacy-link" href="https://fabric.io/terms"
                  target="_blank">https://fabric.io/terms</a></div>
            </div>
            <div class="mb-1">
              <div style="font-weight:bold;font-size:14px;">Google Analytics</div>
              <div style="font-size:12px;"><a class="privacy-link privacy-link"
                  href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
              </div>
            </div>
            <!-- <div class="mb-1">
              <div style="font-weight:bold;font-size:14px;">Appnext Ad Network</div>
              <div style="font-size:12px;"><a class="privacy-link" href="https://www.appnext.com/policy/"
                  target="_blank">https://www.appnext.com/policy/</a></div>
            </div> -->
            <h5 class="header-text-dark mt-1">Do we share your information with anyone else ?</h5>
            <p>We do not share your personal information with companies, organizations, or individuals outside of
              Video Downloader, except when we have a good-faith belief that access, use, preservation, or disclosure of the
              information is reasonably necessary to:</p>
            <ul>
              <li>Meet any applicable law, regulation, legal process, or enforceable governmental request. We share
                information about the number and type of requests we receive from governments in our Transparency
                Report.</li>
              <li>Enforce applicable Terms of Service, including investigation of potential violations.</li>
              <li>Detect, prevent, or otherwise address fraud, security, or technical issues.</li>
              <li>Protect against harm to the rights, property or safety of Video Downloader, our users, or the public as
                required or permitted by law.</li>
            </ul>
            <p></p>
            <hr class="pb-1">
            <h5>PERMISSIONS REQUESTED BY VIDEO DOWNLOADER ANDROID APPLICATION AND WHY ARE THEY REQUIRED </h5>
            <div style="border: 1px solid #E0E0E0;padding:10px;margin-top:50px;">
              <p><b>INTERNET &nbsp;&nbsp;&nbsp;&nbsp;</b>Internet connection is required by the app for fetching all the
                feeds and running the inbuilt browser. In addition the app talks to our servers over ssl
                connections via active internet connection.</p>
              <p><b>ACCESS NETWORK STATE &nbsp;&nbsp;&nbsp;&nbsp;</b>This permission is required to determine the type
                and quality of internet connection. This helps customize and improve user experience and avoid long
                loading times.</p>
              <p><b>STORAGE ACCESS &nbsp;&nbsp;&nbsp;&nbsp;</b>Storage access is required to write all the files you
                download to your phone storage. We do not read or analyze any file on your device, including the ones
                you download via Video Downloader.</p>
              <p><b>WAKE LOCK &nbsp;&nbsp;&nbsp;&nbsp;</b>We require this permission for preventing device CPU from
                sleeping while the app this downloading files or processing data in the background. We make sure that
                wake locks are acquired only when the app needs to do some task in the background.</p>
              <p><b>COARSE LOCATION &nbsp;&nbsp;&nbsp;&nbsp;</b>We need this permission to collect user’s approximate
                location so that we can show personalized and relevant suggestions.</p>
              <p><b>DRAW OVER OTHER APPS &nbsp;&nbsp;&nbsp;&nbsp;</b>This permission is required for the <b>‘Copy Link
                  Detection’</b> feature. The moment you copy any supported downloadable link on your clipboard, our app
                shows a small popup on screen asking whether you wish to download the media. We require this permission
                to show that popup.<b>We do not collect or send any clipboard data to our servers.</b></p>
                <p><b>REQUEST INSTALL PACKAGES &nbsp;&nbsp;&nbsp;&nbsp;</b>
                  Video Downloader need this permision to install Downloaded APKs, you're able to confirm before the installation begin</p>
            </div>
            <hr class="my-3">
            <h5 class="header-text-dark">KEEPING YOUR INFORMATION SECURE</h5>
            <p>All Video Downloader products are built with strong security features that continuously protect your information.
              The insights we gain from maintaining our services help us detect and automatically block security threats
              from ever reaching you. And if we do detect something risky that we think you should know about, we’ll
              notify you and help guide you through steps to stay better protected.</p>
            <p style="font-weight:bold">We work hard to protect you and Video Downloader from unauthorized access, alteration,
              disclosure, or destruction of information we hold, including:</p>
            <p>1. We use encryption to keep your data private while in transit</p>
            <p>2. We review our information collection, storage, and processing practices, including physical security
              measures, to prevent unauthorized access to our systems</p>
            <p>3. We restrict access to personal information to our employees, contractors, and agents who need
              that information in order to process it. Anyone with this access is subject to strict contractual
              confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
            </p>
            <hr class="my-3">
            <h5 class="header-text-dark">CHANGES TO THIS POLICY</h5>
            <p>We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy
              without your explicit consent. We always indicate the date the last changes were published. If changes are
              significant, we’ll provide a more prominent notice (including, for certain services, email notification of
              Privacy Policy changes).</p>
            <hr class="my-3">
            <h5 class="header-text-dark" style="color:#BA2D24">DELETING YOUR INFORMATION</h5>
            <p>Your use of VIDEO DOWNLOADER implies your agreement to this privacy policy. But if you feel that
              you no more want to use the service and want us to delete all the information we’ve collected about you,
              you can do that with just one click. We will also contact third party SDK providers to delete any
              information they have collected via VIDEO DOWNLOADER. Deletion of information from our servers is immediate while
              that from third party partners takes few days.</p>
              <p><a class="btn btn-warning" style="padding-top:10px;padding-bottom:9px;font-size:12px;"
                  href="rubbit://delete_user_information" target="_blank">DELETE INFORMATION AND EXIT VIDEO DOWNLOADER</a></p>
            <h5 class="header-text-dark">QUESTIONS OR CONCERNS</h5>
            <p>If you have any questions or concerns regarding our privacy policies, please send us a detailed message
              to <b style="color:#4A90E2"><a href="mailto:videodownloaders2020@outlook.com">videodownloaders2020@outlook.com</a></b>. We will make
              every effort to resolve your concerns
              in minimum possible time</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
`
